@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  overflow: hidden;
  text-align: center;
  font-family: Consolas, monaco, monospace;
  h1,
  p {
    margin: 0;
  }
  .table {
    position: absolute;
    width: 100%;
    height: 100%;
    display: table;
    text-align: center;
  }
  .wrap {
    display: table-cell;
    vertical-align: middle;
  }
}
